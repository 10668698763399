@import 'utils.scss';

html {
  font-size: 16px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing : antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.capitalize {
  text-transform: capitalize;
}

.flex-column {
  display       : flex;
  flex-direction: column;
}

.upper-case {
  text-transform: uppercase;
}

table {
  thead {
    th {
      text-transform: capitalize;
    }
  }
}