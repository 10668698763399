$margin-length:250;


@for $i from 0 through $margin-length {
  .fs-#{$i} {
    font-size: #{$i}px;
  }

  .p-#{$i} {
    padding: #{$i}px;
  }

  .m-#{$i} {
    margin: #{$i}px;
  }

  .p-t-#{$i} {
    padding-top: #{$i}px;
  }

  .p-l-#{$i} {
    padding-left: #{$i}px;
  }

  .p-r-#{$i} {
    padding-right: #{$i}px;
  }

  .p-b-#{$i} {
    padding-bottom: #{$i}px;
  }

  .m-t-#{$i} {
    margin-top: #{$i}px;
  }

  .m-l-#{$i} {
    margin-left: #{$i}px;
  }

  .m-r-#{$i} {
    margin-right: #{$i}px;
  }

  .m-b-#{$i} {
    margin-bottom: #{$i}px;
  }
}