.config {
  padding: 32px;
  .header {
    text-align: center;
    font-size: 1.2rem;
    color: #5a97fa;
    font-weight: 600;
  }
  .mt-3 {
    margin-top: 1.5rem;
  } 
}

.color-green {
  color: green!important;
}

.d-none {
  display: none;
}

.d-block {
  display: block;
}